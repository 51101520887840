export default {
  methods: {
    buildParams(folderCategory, category, additionalFilters) {
      try {
        let filters = [];

        const params = { folderCategory: folderCategory };

        if (category && typeof category === 'string') {
          params.category = category;
        }

        if (category && typeof category !== 'string' && category.additionalFolder) {
          params.folderCategory = category.additionalFolder;
        }

        if (category && typeof category !== 'string' && category.slug) {
          params.category = category.slug;
        }

        if (category && typeof category !== 'string' && category.url && Array.isArray(category.url.category)) {
          params.category = category.url.category.join('/');
        }

        if (Array.isArray(additionalFilters)) {
          additionalFilters.forEach((filter) => {
            filters.push(filter);
          });
        }

        if (category && typeof category !== 'string' && category.url && Array.isArray(category.url.filters)) {
          filters = [...filters, ...category.url.filters];
        }

        if (filters.length) {
          params.filter = this.$catalogUrlService.buildFilters(filters);
        }

        return params;
      } catch (error) {
        console.error('Build Params Error: ', error);

        return {};
      }
    }
  }
};
