<template>
  <section class="header-top__nav-right-side-link wish-list">
    <uc-link v-if="isAuth" :to="localePath({ name: 'wish-list-page' })" rel="nofollow" class="wishlist-wrapper">
      <wish-list-counter />
    </uc-link>
    <div v-else class="wishlist-wrapper" @click="clickHandler">
      <wish-list-counter />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { RouterMixin } from 'u-mixins';
import WishListCounter from '~/src/components/general/navigation/WishListCounter.vue';

export default {
  components: { WishListCounter },
  mixins: [RouterMixin],
  data() {
    return {
      isClicked: false
    };
  },
  computed: {
    ...mapGetters({
      isAuth: 'user/isAuth'
    })
  },
  mounted() {
    this.$eventBus.$on('auth:session:login', this.redirectToWishList);
  },
  beforeDestroy() {
    this.$eventBus.$off('auth:session:login', this.redirectToWishList);
  },
  methods: {
    clickHandler() {
      this.isClicked = true;
      this.$eventBus.$emit('modals:register:open', 'login');
    },
    redirectToWishList() {
      if (this.isClicked) {
        setTimeout(() => {
          this.moveToByName('wish-list-page');
          this.isClicked = false;
        });
      }
    }
  }
};
</script>
