<template>
  <div class="wishlist">
    <RedHeart />
    <div v-if="wishListProductIds.length" class="counter">
      {{ wishListProductIds.length }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { RedHeart } from '~/src/icons';

export default {
  components: { RedHeart },
  data() {
    return {
      isClicked: false
    };
  },
  computed: {
    ...mapState({
      wishListProductIds: (state) => state.wishList.wishListProductIds
    })
  }
};
</script>
<style lang="scss" scoped>
.wishlist-wrapper .wishlist .counter {
  @media (max-width: $screen-lg - 1) {
    top: -10px;
    left: 15px;
  }
}
</style>
