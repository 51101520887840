<template>
  <div
    v-click-outside="hideWidgetClick"
    class="basket-wrapper"
    @mouseover="displayWidgetMouse()"
    @mouseleave="hideWidgetMouse()"
  >
    <div
      class="header-top__nav-right-side-link basket"
      @click="basketModel && !basketModel.isEmpty ? $eventBus.$emit('basket_modal:display') : displayWidgetClick()"
    >
      <img src="https://static.chicco.com.ua/chicco-assets/svg/header/basket.svg" alt="basket" />
      <div class="text">{{ $t('Корзина') }}</div>
      <img class="arrow" src="https://static.chicco.com.ua/chicco-assets/svg/arrow-down-blue.svg" alt="Arrow" />
      <div v-show="count" class="counter">{{ count }}</div>
    </div>
    <div class="basket-popup" :class="{ 'display-none': !isVisible }">
      <div class="basket-popup-wrapper">
        <client-only>
          <basket-widget-empty v-if="basketModel && basketModel.isEmpty" />
          <basket-widget-list v-else />
        </client-only>
      </div>
    </div>
    <basket-notification />
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import BasketWidgetList from './BasketWidgetList.vue';
import BasketWidgetEmpty from './BasketWidgetEmpty.vue';
import { basketModels } from 'u-models';
import { ScreenWidthMixin } from 'u-mixins';
import BasketNotification from '~/src/components/general/modals/BasketNotification.vue';
const BasketModel = basketModels.basket;

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: { BasketWidgetList, BasketWidgetEmpty, BasketNotification },
  mixins: [ScreenWidthMixin],
  data() {
    return {
      isVisible: false,
      basketModel: null,
      count: 0
    };
  },
  watch: {
    '$store.state.basket.basket': {
      handler(data) {
        this.basketModel = new BasketModel(data);
        this.count = (this.basketModel && this.basketModel.totalCount) || 0;
      },
      deep: true
    },
    '$route.path': {
      handler() {
        this.hideWidget();
      }
    }
  },
  created() {
    if (process.client) {
      this.$basket.updateBasketStore();
    }
  },
  mounted() {
    global.addEventListener('storage', () => {
      this.$basket.updateBasketStore();
    });
    if (this.$store.state.basket.basket) {
      this.$basket.checkBasket();
    }
  },
  beforeDestroy() {
    global.removeEventListener('storage', (e) => {
      this.$basket.updateBasket(e, this.$store.state.user.auth);
    });
  },
  methods: {
    displayWidgetMouse() {
      if (!this.isScreenDesktop) {
        return;
      }

      this.isVisible = true;
    },
    displayWidgetClick() {
      if (this.isScreenDesktop) {
        return;
      }

      this.isVisible = true;
    },
    hideWidget() {
      this.isVisible = false;
    },
    hideWidgetMouse() {
      if (!this.isScreenDesktop) {
        return;
      }

      this.isVisible = false;
    },
    hideWidgetClick() {
      if (this.isScreenDesktop) {
        return;
      }

      this.isVisible = false;
    }
  }
};
</script>
