<template>
  <div v-if="Object.keys(product).length" class="basket-add-notification" :class="{ visible: dialogDisplay }">
    <div class="notification-title">{{ $t('Вы добавили в корзину') }}</div>
    <div class="notification-wrap">
      <uc-image
        :img="product.currentImage"
        :width="'200'"
        :height="'200'"
        alt="product image"
        class="notification-img"
        title="product image"
      />
      <div class="notification-info">
        <div class="info-title">
          {{ product.title }}
        </div>
        <div class="info-text">
          Цена: <span class="info-price__new">{{ product.price.sellingPrices | currency }}</span>
          <span v-if="product.price.recommendedPrice > product.price.sellingPrices" class="info-price__old">{{
            product.price.recommendedPrice | currency
          }}</span>
        </div>
        <div v-if="product.colorPresentationValue && product.colorsCount > 1" class="info-text">
          Цвет: <span>{{ getTranslationBySlug(product.colorPresentationValue) }}</span>
        </div>
        <div v-if="product.sizePresentationValue" class="info-text">
          Размер: <span>{{ product.sizePresentationValue }}</span>
        </div>
        <div v-if="product.displayArticle" class="info-text">
          Артикул: <span>{{ product.displayArticle }}</span>
        </div>
        <div class="info-btn" @click="displayBasket">
          {{ $t('В корзину') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TranslatorMixin } from 'u-mixins';

export default {
  mixins: [TranslatorMixin],
  data() {
    return {
      product: {},
      dialogDisplay: false
    };
  },
  mounted() {
    this.$eventBus.$on('basket_add_notification:display', this.displayModal);
  },
  beforeDestroy() {
    this.$eventBus.$off('basket_add_notification:display', this.displayModal);
  },
  methods: {
    displayModal(data) {
      this.product = data;
      if (!Object.keys(this.product).length) {
        return;
      }
      this.dialogDisplay = true;
      setTimeout(() => {
        this.dialogDisplay = false;
      }, 4000);
    },
    displayBasket() {
      this.$eventBus.$emit('basket_modal:display');
      this.dialogDisplay = false;
    }
  }
};
</script>
